<template>
  <SelectCard
    :onClickNewCard="postPaymentCardNew"
    :onClickCurrentCard="onClickCurrent"
  />
</template>
<script>
import * as Sentry from '@sentry/browser'

import { post as postPaymentCardNew } from '@/api/customer/payment/card/new'
import { post as postPaymentCardCurrent } from '@/api/customer/payment/card/current'
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SelectCard from '@/components/Payment/SelectCard'

export default defineComponent({
  components: { SelectCard },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { locale } = route.params

    const onClickCurrent = () => {
      const redirect = () =>
        router.push(`/${locale}/payment/card/current/success`)

      return postPaymentCardCurrent(redirect)
    }

    return {
      postPaymentCardNew,
      onClickCurrent,
    }
  },
})
</script>
