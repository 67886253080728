<template>
  <div class="container mx-auto max-w-md">
    <div class="md:mt-20 sm:mt-0">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="md:mb-10 px-4 sm:px-0">
            <h3 class="text-xl md:text-3xl leading-6 text-gray-900 font-bold">
              {{ t('Select the payment method') }}
            </h3>
          </div>
        </div>

        <div class="text-left m-auto overflow-hidden sm:rounded-md mb-10">
          <CardNew :onClick="clickNew" />
        </div>
        <div class="text-left m-auto overflow-hidden sm:rounded-md">
          <CardCurrent :onClick="clickCurrent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Sentry from '@sentry/browser'
import CardNew from '@/components/Payment/CardNew.vue'
import CardCurrent from '@/components/Payment/CardCurrent.vue'
import { defineComponent } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: { CardNew, CardCurrent },
  props: {
    onClickNewCard: {
      type: Function,
      required: true,
    },
    onClickCurrentCard: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslation()
    const clickCurrent = () => props.onClickCurrentCard()
    const clickNew = () => props.onClickNewCard()

    return {
      t,
      clickNew,
      clickCurrent,
    }
  },
})
</script>
