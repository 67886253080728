<template>
  <div v-if="creditCard !== undefined">
    <div class="text-lg w-full">
      <p className="text-2xl font-bold mb-4">
        {{ t('Pay with the current card') }}
      </p>
      <p className="font-bold text-sm text-left mb-2">
        {{ t('Card Information') }}
      </p>
      <table className="text-left text-sm mb-2">
        <tr className="">
          <td className="pr-2">{{ t('Type') }}</td>
          <td className="pr-2">{{ creditCard.cardType }}</td>
        </tr>
        <tr className="">
          <td className="pr-2">{{ t('Number') }}</td>
          <td className="pr-2">{{ creditCard.lastNumber }}</td>
        </tr>
        <tr className="">
          <td className="pr-2">{{ t('Expire') }}</td>
          <td className="pr-2">
            {{ creditCard.expireMonth }} / {{ creditCard.expireYear }}
          </td>
        </tr>
        <tr className="">
          <td className="pr-2">{{ t('Name') }}</td>
          <td className="pr-2">{{ creditCard.name }}</td>
        </tr>
        <tr className="">
          <td className="pr-2">{{ t('Email') }}</td>
          <td className="pr-2">{{ creditCard.email }}</td>
        </tr>
      </table>
    </div>
    <div class="py-3 text-right">
      <button
        :disabled="processing"
        class="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 w-24"
        @click="clicked"
      >
        {{ t('Pay') }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, onBeforeMount, reactive, toRefs } from 'vue'
import {
  get as getPaymentCardCurrent,
  CreditCard,
} from '@/api/customer/payment/card/current'
import { useTranslation } from '@/locales'

export default defineComponent({
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslation()
    const processing = ref(false)

    const items = reactive<{ creditCard: CreditCard | undefined }>({
      creditCard: undefined,
    })
    const { creditCard } = toRefs(items)

    onBeforeMount(() => {
      getPaymentCardCurrent()
        .then((res) => (creditCard.value = res))
        .catch(() => (creditCard.value = undefined))
    })

    const clicked = () => {
      processing.value = true
      props
        .onClick()
        .then(() => (processing.value = false))
        .catch(() => (processing.value = false))
    }

    return {
      t,
      processing,
      creditCard,
      clicked,
    }
  },
})
</script>
