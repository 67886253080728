import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'
import { stripeRedirect } from '@/api/utils'

export type CreditCard = {
  cardType: string
  lastNumber: string
  expireYear: string
  expireMonth: string
  name: string
  email: string
}

export const post = () =>
  new Promise<void>((resolve, reject) =>
    http
      .post('customer/payment/card/new')
      .then(async (r) => {
        const session = await r.json()
        await stripeRedirect(session.sessionId)
        resolve()
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)

        reject()
      })
  )
