import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type CreditCard = {
  cardType: string
  lastNumber: string
  expireYear: string
  expireMonth: string
  name: string
  email: string
}

export const get = () =>
  new Promise<CreditCard | undefined>((resolve, reject) =>
    http
      .get('customer/payment/card/current')
      .then(async (r: any) => {
        const result = await r.json()
        resolve({ ...result })
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )

export const post = (redirect: () => void) =>
  new Promise<void>((resolve, reject) =>
    http
      .post('customer/payment/card/current')
      .then(() => {
        redirect()
        resolve()
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject()
      })
  )
