
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, onBeforeMount, reactive, toRefs } from 'vue'
import {
  get as getPaymentCardCurrent,
  CreditCard,
} from '@/api/customer/payment/card/current'
import { useTranslation } from '@/locales'

export default defineComponent({
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslation()
    const processing = ref(false)

    const items = reactive<{ creditCard: CreditCard | undefined }>({
      creditCard: undefined,
    })
    const { creditCard } = toRefs(items)

    onBeforeMount(() => {
      getPaymentCardCurrent()
        .then((res) => (creditCard.value = res))
        .catch(() => (creditCard.value = undefined))
    })

    const clicked = () => {
      processing.value = true
      props
        .onClick()
        .then(() => (processing.value = false))
        .catch(() => (processing.value = false))
    }

    return {
      t,
      processing,
      creditCard,
      clicked,
    }
  },
})
