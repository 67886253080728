<template>
  <div class="text-lg w-full">
    <p className="text-2xl font-bold mb-4">
      {{ t('Pay by registering a new card.') }}
    </p>

    <p className="text-xs">
      {{
        t(
          'When you click the "Register" button, you will be redirected to the Stripe card registration screen.'
        )
      }}
    </p>
  </div>

  <div class="py-3 text-right mt-4">
    <button
      :disabled="processing"
      class="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 w-24"
      @click="clicked"
    >
      {{ t('Register') }}
    </button>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslation()
    const processing = ref(false)

    const clicked = () => {
      processing.value = true
      props
        .onClick()
        .then(() => (processing.value = false))
        .catch(() => (processing.value = false))
    }

    return {
      t,
      processing,
      clicked,
    }
  },
})
</script>
